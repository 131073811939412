<template>
<aheader/>
  <section class="section">
    <div class="title">
      カート
    </div>
    <div class="title is-5 has-text-centered" v-show="length == 0">カート内に商品がありません。</div>
    <div class="columns mt-4">
      <div class="column is-10 is-offset-1">
        <div v-for="item, index in this.$store.state.cartList" :key='index'>
          <div class="columns is-vcentered">
            <div class="column is-8 is-offset-1">
              <div class="columns is-mobile is-vcentered">
                <div class="column is-2">
                  <p class="image is-64x64">
                    <img :src="item.icon">
                  </p>
                </div>
                <div class="column is-7 has-text-centered">
                  <p class="title is-5">
                    {{item.name}}
                  </p>
                  <template v-if="this.kinds[index] != 'one'" >
                    <div class="select">
                      <select v-model="this.kindsDict[item.cart_id]">
                        <option value='' disabled style='display:none;'>{{ this.$store.state.cartList[index].kind }}</option>
                        <option v-for="kind, key in item.kindsList" :key="key">{{key}}</option>
                      </select>
                    </div>
                  </template>
                </div>
                <div class="column is-2-desktop">
                  <p class="subtitle is-6 has-text-right-desktop">
                    {{itemValue(index)}}円
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-2">
              <div class="columns is-centered is-mobile">
                <div class="column is-2-mobile">
                  <button class="button is-small has-text-weight-bold" @click="reduce(index)" :disabled="item.count == 1">－</button>
                </div>
                <div class="column is-2-mobile">
                  <p>{{item.count}}</p>
                </div>
                <div class="column is-2-mobile">
                  <button class="button is-small has-text-weight-bold" @click="add(index)">＋</button>
                </div>
              </div>
            </div>
            <div class="column is-1">
              <button class="button" @click="deleteItems(index)">
                削除
              </button>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
    <!--
    <table class="table is-fullwidth">
      <tbody>
        <tr v-for="item, index in $store.state.cartList" :key="index">
          <td>
            {{item.icon}}
          </td>
          <td>
            <p class="title is-5">
              {{item.name}}
            </p>
            <div class="select">
              <select v-model="this.kindsDict[item.id]">
                <option value='' disabled style='display:none;'>{{ this.$store.state.cartList[index].kind }}</option>
                <option v-for="kind, key in item.kindsList" :key="key">{{key}}</option>
              </select>
            </div>
          </td>
          <td class="has-text-right">
            価格：
          </td>
          <td class="has-text-right">
            {{itemValue(index)}}円
          </td>
          <td>
            <button class="button is-small has-text-weight-bold" @click="reduce(index)" :disabled="item.count == 1">－</button>
          </td>
          <td>
            {{item.count}}
          </td>
          <td>
            <button class="button is-small has-text-weight-bold" @click="add(index)">＋</button>
          </td>
          <td>
            <button class="button" @click="deleteItems(item.id)">
              削除
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    -->
    <div class="columns">
      <div class="column is-4 is-offset-3">
        <div>
          <p class="title is-4">
            小計（税込 ）{{totalPrices}}円
          </p>
        </div>
      </div>
      <div class="column is-3">
        <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('order_customer')" :disabled="this.$store.state.cartList.length == 0">
          <span>
            レジにすすむ
          </span>
          <span class="icon is-small">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>
    </div>
  </section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      cartList: this.$store.state.cartList,
      kinds: [],
      kindsDict: {}
    }
  },
  created() {
    this.makeKinds()
    //console.log(this.$store.state.cartList)
    //console.log(this.$store.state.totalPrices)
  },
  mounted() {
    this.scrollTop()
  },
  computed: {
    totalPrices() {
      return this.$store.getters.totalPrice.toString().replace(/\B(?=(\d{3})+$)/g, ',');
    },
    length() {
      return this.$store.getters.cartLength
    },
    itemValue() {
      return (index) => {
        //console.log("start")
        let cartID = this.$store.state.cartList[index].cart_id
        let changedKind = this.kindsDict[cartID]
        this.$store.commit('changeValue', [index, changedKind])
        //console.log(this.$store.state.cartList[index])
        return this.$store.state.cartList[index].kindsList[changedKind].toString().replace(/\B(?=(\d{3})+$)/g, ',');
      }
    }
  },
  methods: {
    deleteItems(index) {
      //alert(id)
      let deleteName = this.$store.state.cartList[index].name
      let deleteKind = this.$store.state.cartList[index].kind
      this.$store.commit('deleteItem', [deleteName, deleteKind])
    },
    add(index) {
      this.$store.commit('addItem', index);
    },
    reduce(index) {
      this.$store.commit('reduceItem', index);
    },
    pushPage(page) {
      this.$router.push(page);
    },
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    setKind() {
      //console.log(this.kinds)
      this.$store.commit('setKind', this.kinds)
    },
    makeKinds() {
      let range = this.$store.state.cartList.length
      for (let i=0; i<range; i++) {
        this.kindsDict[this.cartList[i].cart_id] = this.cartList[i].kind
        this.kinds.push(this.$store.state.cartList[i].kind)
      }
      //console.log("kinds", this.kinds)
    }
  }
}
</script>
